/* eslint-disable react/no-unescaped-entities */
import { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import { rejectDepositRequest } from 'redux/reducers/unidentifiedDeposits/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions as PERMISSIONS } from 'services/constants/permissions';
import { IRejectDepositModal } from './types';

// ================================================:
export const RejectDepositModal: FC<IRejectDepositModal> = ({ id, openModal, closeModal }) => {
	const dispatch = useDispatch();
	const [comment, setComment] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { checkEditable } = usePermission();

	const canEdit = checkEditable(PERMISSIONS.MANAGE_UNIDENTIFIED_DEPOSITS);

	const handleSubmit = () => {
		if (!canEdit) return;
		setIsSubmitting(true);
		dispatch(
			rejectDepositRequest({
				apiParams: {
					id,
					comment,
				},
				onSuccess: () => {
					closeModal();
					setIsSubmitting(false);
				},
				onError: () => {
					setIsSubmitting(false);
				},
			}),
		);
	};

	useEffect(() => {
		setComment('');
	}, [openModal]);

	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup-window admin-managment-popup">
				<div className="popup-window__inside">
					<div className="popup">
						<button
							onClick={() => {
								closeModal();
								setComment('');
							}}
							type="button"
							className="popup__close"
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1.80005 2.30005L16.2 16.7M1.80005 16.7L16.2 2.30005" stroke="#011E26" />
							</svg>
						</button>
						<div className="popup-header popup-header--max-width-300">
							<h3 className="popup-header__title">Are you sure you want to reject transfer?</h3>
						</div>
						<div className="popup-body popup-body--type2">
							<div className="input">
								<p className="input__name input__name--fw-500">Reason</p>
								<div className="textarea ">
									<textarea
										className="textarea__item textarea__item--size3 "
										onChange={(e) => setComment(e.target.value)}
										placeholder="Enter reason"
										maxLength={256}
									/>
								</div>
							</div>

							<div className="popup-submit popup-submit--type2 popup-submit--big-mt">
								<button
									onClick={closeModal}
									type="button"
									className="button button--cancel button--full-width"
								>
									Cancel
								</button>
								<button
									className="button button--check button--full-width"
									type="button"
									disabled={!comment || isSubmitting}
									onClick={handleSubmit}
								>
									Yes, I'm sure
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
