import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import UnidentifiedDeposits from 'components/UnidentifiedDeposits';
import { permissions } from 'services/constants/permissions';
import PermissionGate from 'ui/PermissionGate';

// ================================================:
const UnidentifiedDepositsPage: FC = () => {
	return (
		<PermissionGate permissions={permissions.MANAGE_UNIDENTIFIED_DEPOSITS}>
			<Dashboard title="Unidentified deposits">
				<UnidentifiedDeposits />
			</Dashboard>
		</PermissionGate>
	);
};

export default UnidentifiedDepositsPage;
