import { FC, useState } from 'react';
import { getDateOffset } from 'utils/dateAndTimeHelpers';
import { capitalizeFirstLetter } from 'utils/numberFormat';
import usePermission from 'services/hooks/usePermission';
import { permissions as PERMISSIONS } from 'services/constants/permissions';
import { AcceptDepositModal } from '../AcceptDepositModal';
import { RejectDepositModal } from '../RejectDepositModal';
import { getStatusClass } from './utils';
import { ITableRow } from './types';

const TableRow: FC<ITableRow> = ({ item }) => {
	const createdAtOffset = getDateOffset(item.created_at);
	const updatedAtOffset = getDateOffset(item.updated_at);
	const [acceptOpen, setAcceptOpen] = useState(false);
	const [rejectOpen, setRejectOpen] = useState(false);

	const { checkEditable } = usePermission();

	const canEdit = checkEditable(PERMISSIONS.MANAGE_UNIDENTIFIED_DEPOSITS);

	return (
		<>
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Payment ID</p>
					<p>{item.payment_id}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Extrnal payment ID</p>
					<p>{item.external_payment_id}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">User ID</p>
					<p>
						{item.user_id && (
							<a href={`/user-management/${item.user_id}`} className="link">
								{item.user_id}
							</a>
						)}
					</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Full name</p>
					<p>{item.full_name}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Reference code</p>
					<p>{item.reference_code}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Sender name</p>
					<p>{item.sender_name}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Currency</p>
					<p>{item.asset?.code?.toUpperCase()}</p>
				</div>
				<div className="td ">
					<p className="td-hidden-name">Amount</p>
					<p>{item.amount}</p>
				</div>
				<div className="td ">
					<p className="td-hidden-name">Payment Method</p>
					<p>{item.external_processing_system}</p>
				</div>
				<div className="td ">
					<p className="td-hidden-name">Bank name</p>
					<p>{item.sender_bank_name}</p>
				</div>
				<div className="td ">
					<p className="td-hidden-name">IBAN</p>
					<p>{item.sender_iban}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Created</p>
					<p>
						{createdAtOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
						{createdAtOffset.toLocaleTimeString()}
					</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Date of confirm</p>
					<p>
						{updatedAtOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
						{updatedAtOffset.toLocaleTimeString()}
					</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Admin ID</p>
					<p>
						{item.admin_id && (
							<a href={`/admin-management/edit/${String(item.admin_id)}`} className="link">
								{item.admin_id}
							</a>
						)}
					</p>
				</div>
				<div className="td ">
					<p className="td-hidden-name">Status</p>
					<div>
						<span className={`status ${getStatusClass(item.status)}`}>
							{capitalizeFirstLetter(item.status)}
						</span>
					</div>
				</div>
				<div className="td">
					<p className="td-hidden-name">Reason</p>
					{item.reason &&
						capitalizeFirstLetter(item.reason.replaceAll('_', ' ').replaceAll('iban', 'IBAN'))}
				</div>
				<div className="td">
					<p className="td-hidden-name">Reason of rejection</p>
					<p>
						<div className="hint-block">
							<div className="hint-block__text">
								<p>{item.comment}</p>
							</div>
							<div className="hint-item">
								<p>{item.comment}</p>
							</div>
						</div>
					</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Actions</p>

					<div className="table-buttons">
						<button
							disabled={item.status !== 'unidentified' || !canEdit}
							type="button"
							onClick={() => setRejectOpen(true)}
						>
							<span className="edit-icon icon-cancel-icon" />
						</button>
						<button
							disabled={item.status !== 'unidentified' || !canEdit}
							type="button"
							onClick={() => setAcceptOpen(true)}
						>
							<span className="edit-icon icon-succes-icon" />
						</button>
					</div>
				</div>
			</div>
			<AcceptDepositModal
				id={item.id}
				openModal={acceptOpen}
				closeModal={() => setAcceptOpen(false)}
			/>
			<RejectDepositModal
				id={item.id}
				openModal={rejectOpen}
				closeModal={() => setRejectOpen(false)}
			/>
		</>
	);
};

export default TableRow;
