import { FC, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { navList } from 'routes/routesList';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';

// ==========================================:
const Nav: FC = () => {
	const isUserManagementPathname = (pathname: string): boolean =>
		pathname === '/user-management' ||
		pathname === '/2FA' ||
		pathname === '/email-2fa' ||
		pathname === '/sms-2fa';
	const location = useLocation();
	const [open, setOpen] = useState<boolean>(isUserManagementPathname(location.pathname));

	const { checkReadable } = usePermission();

	useEffect(() => {
		if (isUserManagementPathname(location.pathname)) {
			setOpen(true);
		}
	}, [location.pathname]);

	return (
		<nav className="sidebar-nav">
			<ul className="sidebar-nav__list sidebar-nav__list-custom">
				{checkReadable(permissions.VIEW_ADMIN_SYSTEM) && (
					<li>
						<NavLink to="/" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-admin-panel-icon" />
							Admin system
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_USERS) && (
					<li>
						<NavLink to="/user-management" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-user-managment-icon" /> User
							Management
						</NavLink>
					</li>
				)}
				{open && checkReadable(permissions.MANAGE_2FA_RESETS) && (
					<li>
						<NavLink
							to="/2FA"
							className="sidebar-nav__subitem-custom"
							activeClassName="active"
							exact
						>
							2FA reset request
						</NavLink>
					</li>
				)}
				{open && (
					<li>
						<NavLink
							to="/email-2fa"
							className="sidebar-nav__subitem-custom"
							activeClassName="active"
							exact
						>
							Email
						</NavLink>
					</li>
				)}
				{open && (
					<li>
						<NavLink
							to="/sms-2fa"
							className="sidebar-nav__subitem-custom"
							activeClassName="active"
							exact
						>
							SMS
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_WITHDRAWALS) && (
					<li>
						<NavLink to="/pending-withdrawals" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-withdrawals-icon" /> Pending
							Withdrawals
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_PENDING_DEPOSITS) && (
					<li>
						<NavLink to="/pending-deposits" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon sidebar-icon--stroke">
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.3026 5.86914C12.7776 6.08227 13.7882 7.35414 13.7882 10.1385V10.2279C13.7882 13.301 12.5576 14.5316 9.48445 14.5316H5.00883C1.9357 14.5316 0.705078 13.301 0.705078 10.2279V10.1385C0.705078 7.37477 1.70195 6.10289 4.1357 5.87602"
										stroke="#777E90"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M7.25 1.125V9.98"
										stroke="#777E90"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M9.55352 8.44727L7.25039 10.7504L4.94727 8.44727"
										stroke="#777E90"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>{' '}
							Pending Deposits
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_UNIDENTIFIED_DEPOSITS) && (
					<li>
						<NavLink to={navList.unidentifiedDeposits.path} activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon">
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M0.5 8C0.5 4.13401 3.63401 1 7.5 1C9.73096 1 11.7182 2.04367 13 3.6694M14.5 8C14.5 11.866 11.366 15 7.5 15C5.26904 15 3.28183 13.9563 2 12.3306M5 12H1.5V15.5M13.5 0.5V4L10 4"
										stroke="777E90"
									/>
								</svg>
							</span>
							Unidentified deposits
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.VIEW_ORDERS) && (
					<li>
						<NavLink to="/orders" activeClassName="active" exact>
							<span className="sidebar-nav__icon">
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.625 14.25H9.375C12.5 14.25 13.75 13 13.75 9.875V6.125C13.75 3 12.5 1.75 9.375 1.75H5.625C2.5 1.75 1.25 3 1.25 6.125V9.875C1.25 13 2.5 14.25 5.625 14.25Z"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path d="M9.84375 6.125H5.15625" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M9.84375 9.875H5.15625" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</span>
							Orders
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_CURRENCIES) && (
					<li>
						<NavLink to="/currencies" activeClassName="active" exact>
							<span className="sidebar-nav__icon">
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M5.625 14.25H9.375C12.5 14.25 13.75 13 13.75 9.875V6.125C13.75 3 12.5 1.75 9.375 1.75H5.625C2.5 1.75 1.25 3 1.25 6.125V9.875C1.25 13 2.5 14.25 5.625 14.25Z"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path d="M9.84375 6.125H5.15625" strokeLinecap="round" strokeLinejoin="round" />
									<path d="M9.84375 9.875H5.15625" strokeLinecap="round" strokeLinejoin="round" />
								</svg>
							</span>
							Currencies
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.VIEW_TRADES) && (
					<li>
						<NavLink to="/trades" activeClassName="active" exact>
							<span className="sidebar-nav__icon">
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M6.31874 7.46875H4.66249C4.26874 7.46875 3.95 7.78747 3.95 8.18122V11.3812H6.31874V7.46875V7.46875Z"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M7.97579 4.625H7.02578C6.63203 4.625 6.31329 4.94376 6.31329 5.33751V11.375H8.68204V5.33751C8.68204 4.94376 8.36954 4.625 7.97579 4.625Z"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M10.3426 8.53125H8.68633V11.375H11.0551V9.24376C11.0488 8.85001 10.7301 8.53125 10.3426 8.53125Z"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M5.625 14.25H9.375C12.5 14.25 13.75 13 13.75 9.875V6.125C13.75 3 12.5 1.75 9.375 1.75H5.625C2.5 1.75 1.25 3 1.25 6.125V9.875C1.25 13 2.5 14.25 5.625 14.25Z"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							Trades
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_ASSET_PAIRS) && (
					<li>
						<NavLink to="/trading-pairs" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-trading-pairs-icon" /> Trading
							Pairs
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_CRYPTO_TRANSFERS) && (
					<li>
						<NavLink to="/transactions" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-transactions-icon" />{' '}
							Transactions
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.SET_FEES) && (
					<li>
						<NavLink to="/fee-management" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-fee-management-icon" /> Fee
							Management
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.SET_FEES) && (
					<li>
						<NavLink to="/fee-management-fiat" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-fee-management-icon" /> Fee
							management fiat
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_WALLETS) && (
					<li>
						<NavLink to="/wallet-management" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-finacial-system-icon" /> Wallet
							Management
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_ADMINS) && (
					<li>
						<NavLink to="/admin-management" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-admin-managment-icon" /> Admin
							Management
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_TOKEN_MANAGEMENT) && (
					<li>
						<NavLink to="/token-management" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-admin-managment-icon" /> Token
							management
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_DISPUTES) && (
					<li>
						<NavLink to="/dispute" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-ipWhitelisting-icon" />
							Dispute
						</NavLink>
					</li>
				)}
				{/* <li> */}
				{/*	<NavLink to="/ip-whitelisting" activeClassName="active" exact> */}
				{/*		<span className="sidebar-nav__icon sidebar-icon icon-ipWhitelisting-icon" /> IP & */}
				{/*		Whitelisting */}
				{/*	</NavLink> */}
				{/* </li> */}
				{checkReadable(permissions.SET_LIMITS) && (
					<li>
						<NavLink to="/limitations" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-limitations-icon" /> Limitations
						</NavLink>
					</li>
				)}

				{checkReadable(permissions.MANAGE_NOTIFICATIONS) && (
					<li>
						<NavLink to="/notification" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-notification-icon" />{' '}
							Notification
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_MARKET_MAKER) && (
					<li>
						<NavLink
							to={navList.internal.path}
							activeClassName="active"
							exact
							className="market-robot"
						>
							<span className="sidebar-nav__icon sidebar-icon robot-icon">
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M7.5 3C10.2614 3 12.5 5.23858 12.5 8V14C12.5 14.5523 12.0523 15 11.5 15H3.5C2.94772 15 2.5 14.5523 2.5 14V8C2.5 5.23858 4.73858 3 7.5 3ZM7.5 3V0.5M4 12H11M0.5 8.5V12.5M14.5 8.5V12.5M5.5 10C4.94772 10 4.5 9.55228 4.5 9C4.5 8.44772 4.94772 8 5.5 8C6.05228 8 6.5 8.44772 6.5 9C6.5 9.55228 6.05228 10 5.5 10ZM9.5 10C8.94772 10 8.5 9.55228 8.5 9C8.5 8.44772 8.94772 8 9.5 8C10.0523 8 10.5 8.44772 10.5 9C10.5 9.55228 10.0523 10 9.5 10Z" />
								</svg>
							</span>
							Internal
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_EXTERNAL_SERVICES) && (
					<li>
						<NavLink
							to={navList.external.path}
							activeClassName="active"
							exact
							className="market-robot"
						>
							<span className="sidebar-nav__icon sidebar-icon robot-icon">
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M7.5 3C10.2614 3 12.5 5.23858 12.5 8V14C12.5 14.5523 12.0523 15 11.5 15H3.5C2.94772 15 2.5 14.5523 2.5 14V8C2.5 5.23858 4.73858 3 7.5 3ZM7.5 3V0.5M4 12H11M0.5 8.5V12.5M14.5 8.5V12.5M5.5 10C4.94772 10 4.5 9.55228 4.5 9C4.5 8.44772 4.94772 8 5.5 8C6.05228 8 6.5 8.44772 6.5 9C6.5 9.55228 6.05228 10 5.5 10ZM9.5 10C8.94772 10 8.5 9.55228 8.5 9C8.5 8.44772 8.94772 8 9.5 8C10.0523 8 10.5 8.44772 10.5 9C10.5 9.55228 10.0523 10 9.5 10Z" />
								</svg>
							</span>
							External
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_MARKET_MAKER) && (
					<li>
						<NavLink
							to={navList.marketBot.path}
							activeClassName="active"
							exact
							className="market-robot"
						>
							<span className="sidebar-nav__icon sidebar-icon robot-icon">
								<svg
									width="15"
									height="16"
									viewBox="0 0 15 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M7.5 3C10.2614 3 12.5 5.23858 12.5 8V14C12.5 14.5523 12.0523 15 11.5 15H3.5C2.94772 15 2.5 14.5523 2.5 14V8C2.5 5.23858 4.73858 3 7.5 3ZM7.5 3V0.5M4 12H11M0.5 8.5V12.5M14.5 8.5V12.5M5.5 10C4.94772 10 4.5 9.55228 4.5 9C4.5 8.44772 4.94772 8 5.5 8C6.05228 8 6.5 8.44772 6.5 9C6.5 9.55228 6.05228 10 5.5 10ZM9.5 10C8.94772 10 8.5 9.55228 8.5 9C8.5 8.44772 8.94772 8 9.5 8C10.0523 8 10.5 8.44772 10.5 9C10.5 9.55228 10.0523 10 9.5 10Z" />
								</svg>
							</span>
							Market Bot
						</NavLink>
					</li>
				)}
				<li>
					<NavLink to="/settings" activeClassName="active" exact>
						<span className="sidebar-nav__icon sidebar-icon icon-settings-icon" /> Settings
					</NavLink>
				</li>
				{checkReadable(permissions.MANAGE_CONVERT_SETTINGS) && (
					<li>
						<NavLink to="/convert-settings" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon icon-settings-icon" /> Convert
							Settings
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_BANK_ACCOUNTS) && (
					<li>
						<NavLink to="/bank-account" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon robot-icon">
								<svg
									width="17"
									height="18"
									viewBox="0 0 17 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.76208 2.02297L15.1371 4.57295C15.385 4.67212 15.5833 4.96961 15.5833 5.2317V7.58337C15.5833 7.97295 15.2646 8.2917 14.875 8.2917H2.125C1.73542 8.2917 1.41667 7.97295 1.41667 7.58337V5.2317C1.41667 4.96961 1.615 4.67212 1.86292 4.57295L8.23792 2.02297C8.37959 1.9663 8.62041 1.9663 8.76208 2.02297Z"
										stroke="#777E90"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M15.5833 16.0833H1.41667V13.9583C1.41667 13.5687 1.73542 13.25 2.125 13.25H14.875C15.2646 13.25 15.5833 13.5687 15.5833 13.9583V16.0833Z"
										stroke="#777E90"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M2.83333 13.2493V8.29102"
										stroke="#777E90"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M5.66667 13.2493V8.29102"
										stroke="#777E90"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M8.5 13.2493V8.29102"
										stroke="#777E90"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M11.3333 13.2493V8.29102"
										stroke="#777E90"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M14.1667 13.2493V8.29102"
										stroke="#777E90"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M0.708333 16.084H16.2917"
										stroke="#777E90"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M8.5 6.52148C9.0868 6.52148 9.5625 6.04579 9.5625 5.45898C9.5625 4.87218 9.0868 4.39648 8.5 4.39648C7.9132 4.39648 7.4375 4.87218 7.4375 5.45898C7.4375 6.04579 7.9132 6.52148 8.5 6.52148Z"
										stroke="#777E90"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							Bank account
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_REFERRALS) && (
					<li>
						<NavLink to="/referral" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon sidebar-icon--stroke">
								<svg
									width="14"
									height="20"
									viewBox="0 0 14 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M12.9587 16.875H9.29199"
										stroke="#777E90"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M11.125 18.7077V15.041"
										stroke="#777E90"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M7.14639 8.96482C7.05472 8.95565 6.94472 8.95565 6.84389 8.96482C4.66222 8.89148 2.92972 7.10398 2.92972 4.90398C2.92056 2.65815 4.74472 0.833984 6.99056 0.833984C9.23639 0.833984 11.0606 2.65815 11.0606 4.90398C11.0606 7.10398 9.31889 8.89148 7.14639 8.96482Z"
										stroke="#777E90"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M6.99062 18.9929C5.32229 18.9929 3.66312 18.5712 2.39812 17.7279C0.179792 16.2429 0.179792 13.8229 2.39812 12.347C4.91896 10.6604 9.05312 10.6604 11.574 12.347"
										stroke="#777E90"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>{' '}
							</span>{' '}
							Referral
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_TRANSLATIONS) && (
					<li>
						<NavLink to="/translations" activeClassName="active" exact>
							Translations
						</NavLink>
					</li>
				)}
				{checkReadable(permissions.MANAGE_STAKING) && (
					<li>
						<NavLink to="/staking" activeClassName="active" exact>
							<span className="sidebar-nav__icon sidebar-icon sidebar-icon--stroke">
								<svg
									width="18"
									height="18"
									viewBox="0 0 18 18"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M0.666992 17.334H17.3337"
										stroke="#777E90"
										strokeMiterlimit="10"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M7.125 2.33268V17.3327H10.875V2.33268C10.875 1.41602 10.5 0.666016 9.375 0.666016H8.625C7.5 0.666016 7.125 1.41602 7.125 2.33268Z"
										stroke="#777E90"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M1.5 7.33268V17.3327H4.83333V7.33268C4.83333 6.41602 4.5 5.66602 3.5 5.66602H2.83333C1.83333 5.66602 1.5 6.41602 1.5 7.33268Z"
										stroke="#777E90"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M13.167 11.5007V17.334H16.5003V11.5007C16.5003 10.584 16.167 9.83398 15.167 9.83398H14.5003C13.5003 9.83398 13.167 10.584 13.167 11.5007Z"
										stroke="#777E90"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							</span>
							Staking
						</NavLink>
					</li>
				)}
			</ul>
		</nav>
	);
};

export default Nav;
