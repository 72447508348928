import { FC, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Popup from 'reactjs-popup';
import debounce from 'lodash.debounce';
import { useSelect } from 'services/hooks/useSelect';
import {
	findUsersRequest,
	resetUsers,
	acceptDepositRequest,
} from 'redux/reducers/unidentifiedDeposits/reducer';
import {
	getSearchUsers,
	getSearchUsersLoader,
} from 'redux/reducers/unidentifiedDeposits/selectors';
import usePermission from 'services/hooks/usePermission';
import { permissions as PERMISSIONS } from 'services/constants/permissions';
import { searchOptions, getInputPlaceholder } from './utils';
import { IAcceptDepositModal } from './types';

// ================================================:
export const AcceptDepositModal: FC<IAcceptDepositModal> = ({ id, openModal, closeModal }) => {
	const dispatch = useDispatch();
	const [searchField, setSearchField] = useState('user_id');
	const [searchValue, setSearchValue] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const users = useSelector(getSearchUsers);
	const usersLoading = useSelector(getSearchUsersLoader);
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();

	const { checkEditable } = usePermission();

	const canEdit = checkEditable(PERMISSIONS.MANAGE_UNIDENTIFIED_DEPOSITS);

	const searchedUser = users?.[0];

	const findUserDebounce = useMemo(
		() =>
			debounce(({ _searchValue, _searchField }: { _searchValue: string; _searchField: string }) => {
				dispatch(
					findUsersRequest({
						search_value: _searchValue,
						search_field: _searchField,
					}),
				);
			}, 200),
		[dispatch],
	);

	useEffect(() => {
		if (!findUserDebounce) return;
		if (searchValue) {
			findUserDebounce({ _searchField: searchField, _searchValue: searchValue });
		} else {
			dispatch(resetUsers());
		}
	}, [searchField, searchValue, findUserDebounce, dispatch]);

	useEffect(() => {
		setSearchField('user_id');
		setSearchValue('');
		dispatch(resetUsers());
	}, [dispatch, openModal]);

	const handleConfirm = () => {
		if (!searchedUser || !canEdit) return;
		setIsSubmitting(true);
		dispatch(
			acceptDepositRequest({
				apiParams: {
					id,
					user_id: searchedUser?.id,
				},
				onSuccess: () => {
					closeModal();
					setIsSubmitting(false);
				},
				onError: () => {
					setIsSubmitting(false);
				},
			}),
		);
	};

	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup-window admin-managment-popup">
				<div className="popup-window__inside">
					<div className="popup">
						<button onClick={closeModal} type="button" className="popup__close">
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1.80005 2.30005L16.2 16.7M1.80005 16.7L16.2 2.30005" stroke="#011E26" />
							</svg>
						</button>
						<div className="popup-header">
							<h3 className="popup-header__title">Accept withdrawal</h3>
						</div>
						<div className="popup-body popup-body--type2">
							<div className="input   ">
								<p className="input__name">Search</p>
								<div className="input-with-select">
									<div className={`select select--height-48 ${open ? 'active' : ''}`}>
										<button
											type="button"
											className="select__current"
											ref={triggerRef}
											onClick={toggleOpen}
										>
											{searchOptions.find((item) => item.name === searchField)?.label}
											<span className="select__current-arrow">
												<svg
													className="stroke"
													width="8"
													height="5"
													viewBox="0 0 8 5"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
												</svg>
											</span>
										</button>
										<div className="select__drop" ref={dropRef}>
											<div className="select__drop-scroll">
												<div className="select-drop__item">
													<ul>
														{searchOptions.map(({ name, label }) => (
															<li key={name}>
																<button
																	type="button"
																	onClick={() => {
																		setSearchField(name);
																		setSearchValue('');
																		setOpen(false);
																	}}
																>
																	{label}
																</button>
															</li>
														))}
													</ul>
												</div>
											</div>
										</div>
									</div>
									<div className="input-wrapper">
										<input
											className="input-item input-item--right-icon "
											type="text"
											placeholder={getInputPlaceholder(searchField)}
											value={searchValue}
											onChange={(e) => setSearchValue(e.target.value)}
										/>
										<span className="input-icon input-icon--right icon-search-icon" />
									</div>
								</div>
							</div>
							<div className="input ">
								<p className="input__name">Full name</p>
								<div className="input-wrapper">
									<input
										className="input-item"
										type="text"
										placeholder="name"
										value={searchedUser?.username}
										disabled
									/>
								</div>
							</div>
						</div>
						<div className="popup-submit popup-submit--type2 popup-submit--big-mt">
							<button
								type="button"
								className="button button--cancel button--full-width "
								onClick={closeModal}
							>
								Cancel
							</button>
							<button
								type="button"
								className="button button--check button--full-width "
								disabled={!searchedUser || usersLoading || isSubmitting}
								onClick={handleConfirm}
							>
								Confirm
							</button>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
