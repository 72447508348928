export const permissions = {
	VIEW_ORDERS: 'view orders',
	MANAGE_ASSET_PAIRS: 'manage assets pairs',
	MANAGE_DISPUTES: 'manage disputes',
	SET_LIMITS: 'set limits',
	SET_FEES: 'set fees',
	MANAGE_CURRENCIES: 'manage currencies',
	VIEW_PROFITS: 'view profits',
	MANAGE_WALLETS: 'manage wallets',
	MANAGE_BANK_ACCOUNTS: 'manage bank accounts',
	MANAGE_ADMINS: 'manage admins',
	MANAGE_USERS: 'manage users',
	MANAGE_CRYPTO_TRANSFERS: 'manage crypto transfers',
	MANAGE_FIAT_BANK_TRANSFERS: 'manage fiat bank transfers',
	MANAGE_ASSET_INTEREST: 'manage assets interest',
	MANAGE_WITHDRAWALS: 'manage withdrawals',
	CHANGE_PASSWORD: 'change password',
	MANAGE_MARKET_MAKER: 'manage market maker',
	MANAGE_EXTERNAL_SERVICES: 'manage external services',
	MANAGE_CONVERT_SETTINGS: 'manage convert settings',
	MANAGE_NOTIFICATIONS: 'manage notifications',
	MANAGE_2FA_RESETS: 'manage 2fa resets',
	MANAGE_REFERRALS: 'manage referrals',
	MANAGE_STAKING: 'manage staking',
	VIEW_ADMIN_SYSTEM: 'view admin system',
	VIEW_TRADES: 'view trades',
	MANAGE_TRANSLATIONS: 'manage translations',
	MANAGE_TOKEN_MANAGEMENT: 'manage token management',
	MANAGE_PENDING_DEPOSITS: 'manage pending deposits',
	MANAGE_REDEMPTIONS: 'manage redemptions',
	MANAGE_UNIDENTIFIED_DEPOSITS: 'manage unidentified deposits',
};
